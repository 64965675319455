import '../../css/brochure/app.css';
import * as Sentry from '@sentry/vue';
import AboutUs from '@brochure/pages/AboutUs.vue';
import CareersPage from '@js/domains/brochure/pages/CareersPage.vue';
import CharitiesBusinessPage from '@brochure/pages/CharitiesBusinessPage.vue';
import CharityLandingPage from '@brochure/pages/CharityLandingPage.vue';
import ComplaintsPage from '@brochure/pages/ComplaintsPage.vue';
import ContactUsPage from '@brochure/pages/ContactUsPage.vue';
import DeathConciergePage from '@brochure/pages/DeathConciergePage.vue';
import EstatePlanningPage from '@brochure/pages/EstatePlanningPage.vue';
import FreeWillPage from '@brochure/pages/FreeWillPage.vue';
import FuneralDirectorsPage from '@brochure/pages/FuneralDirectorsPage.vue';
import GuidesPage from '@brochure/pages/GuidesPage.vue';
import HomePage from '@brochure/pages/HomePage.vue';
import LegacyBoxPage from '@brochure/pages/LegacyBoxPage.vue';
import LifeInsurancePage from '@brochure/pages/LifeInsurancePage.vue';
import LpaPage from '@brochure/pages/LpaPage.vue';
import MainNav from '@components/MainNav/MainNav.vue';
import OLFooter from '@components/Footer/OLFooter.vue';
import OnlineWillPage from '@brochure/pages/OnlineWillPage.vue';
import ProbatePage from '@brochure/pages/ProbatePage.vue';
import SupportHubsPage from '@brochure/pages/SupportHubsPage.vue';
import WillWritingPage from '@brochure/pages/WillWritingPage.vue';
import store from './store';
import { ZiggyVue } from 'ziggy-js';
import { createApp } from 'vue';

// Used by remaining blade template pages
import OlButton from '@components/Form/Button.vue';
import OlInput from '@components/Form/Input.vue';
import OlInputWithPrompt from '@components/Form/InputWithPrompt.vue';
import CreatePasswordInput from '@components/Form/CreatePasswordInput.vue';
import ButtonRounded from '@components/Button/ButtonRounded.vue';
import Cta from '@components/Button/Cta.vue';
import Container from '@components/Core/Container.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import { vSafeHtml } from '@js/directives/SafeHtmlRender.js';
import TermsAndConditions from '@brochure/pages/TermsAndConditions.vue';
import CookiesNoticePage from '@js/domains/brochure/pages/CookiesNoticePage.vue';
import PrivacyNoticePage from '@js/domains/brochure/pages/PrivacyNoticePage.vue';
import TrusteeTermsPage from '@js/domains/brochure/pages/TrusteeTermsPage.vue';
import OLPrivacyNoticePage from '@js/domains/brochure/pages/OLPrivacyNoticePage.vue';
import IfaLandingPage from '@js/domains/brochure/pages/IfaLandingPage.vue';

const app = createApp({
  components: {
    TermsAndConditions,
    AboutUs,
    CareersPage,
    CharitiesBusinessPage,
    IfaLandingPage,
    CharityLandingPage,
    ComplaintsPage,
    ContactUsPage,
    DeathConciergePage,
    EstatePlanningPage,
    FreeWillPage,
    FuneralDirectorsPage,
    GuidesPage,
    HomePage,
    LegacyBoxPage,
    LifeInsurancePage,
    LpaPage,
    MainNav,
    OLFooter,
    OnlineWillPage,
    ProbatePage,
    SupportHubsPage,
    WillWritingPage,
    CookiesNoticePage,
    PrivacyNoticePage,
    TrusteeTermsPage,
    OLPrivacyNoticePage,
    // Components still present in old blade templates - Ts&Cs & login/register pages
    // (TODO: Remove these (& imports) when remaining brochure blade templates are moved to Vue)
    OlButton,
    OlInput,
    OlInputWithPrompt,
    CreatePasswordInput,
    ButtonRounded,
    Cta,
    Container,
    SectionCta,
  },
});
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  tracesSampleRate: 0.2,
});
app.use(ZiggyVue);
app.use(store);
app.mixin({
  methods: {
    appRoute: window.route,
  },
});

app.directive('safe-html', vSafeHtml);

// app.prototype.$route = (...args) => route(...args);

app.mount('#app');
