<template>
  <OLAboveFold
    :header="heroSection.header"
    header-class="w-full lg:w-7/12"
    :sub-header="heroSection.subHeader"
    sub-header-class="w-full lg:w-11/12"
    :cta-text="heroSection.ctaText"
    :caption="heroSection.caption"
    :hero-link="ifaHubspotLink"
    :fold-bg-colour="heroSection.foldBgColour"
    :image="heroSection.image"
    mobile-cta-bottom-padding="md:pb-4 lg:pb-0"
    :pre-call-us="ProudlyFeaturedIn"
    :pre-call-us-props="featuredSources"
    :alternate-call-us="OLParagraphCard"
    :alternate-call-us-props="{
      paragraph: 'Supporting over 400,000 people with estate planning services',
      class: 'text-center',
    }"
  />
  <ShowcaseSection
    :heading="planningHeading"
    :subheading="planningSubheading"
    :features="planningFeatures"
  />

  <ImageSection
    :image="sectionImage.image"
    :figure-caption="sectionImage.figureCaption"
    :image-text-1="sectionImage.imageText1"
    :image-text-2="sectionImage.imageText2"
  />

  <IfaShowcaseSection :show-case-content="showCaseFeatures" />

  <BrochureSteps
    :header="stepsSection.header"
    :sub-header="stepsSection.subHeader"
    :steps="stepsSection.steps"
    :with-extra-padding="true"
    :cta-link="stepsSection.ctaLink"
    :cta-text="stepsSection.ctaText"
  />
  <OLCtaSection
    header="Refer a client"
    content="Refer a client to discuss their estate planning needs."
    :button-route="ifaHubspotLink"
    button-text="Refer a client"
    class="!mb-0"
  />
  <SamsStory :title="samSection.title" :body="samSection.body" />
  <OLTrustpilotReviews />
  <SectionCta class="mt-2 md:mt-3 lg:mt-28 xl:mt-36" />
</template>

<script setup>
import BrochureSteps from '@components/Brochure/BrochureSteps.vue';
import SectionCta from '@components/Footer/SectionCta.vue';
import SamsStory from '@components/SamsStory.vue';
import OLAboveFold from '@components/OLAboveFold.vue';
import ProudlyFeaturedIn from '@components/Brochure/ProudlyFeaturedIn.vue';
import OLParagraphCard from '@components/Brochure/OLParagraphCard.vue';
import ShowcaseSection from '@components/Brochure/homePage/ShowcaseSection.vue';
import ImageSection from '@components/Brochure/homePage/ImageSection.vue';
import OLTrustpilotReviews from '@components/Brochure/OLTrustpilotReviews.vue';
import {
  DocumentTextIcon,
  PencilIcon,
  ScaleIcon,
  ShieldCheckIcon,
} from '@heroicons/vue/24/outline/index.js';
import IfaShowcaseSection from '@js/domains/brochure/components/ifa/IfaShowcaseSection.vue';
import OLCtaSection from '@components/OLCtaSection.vue';

/** AboveFold hero section data */
const heroSection = {
  header: 'Estate planning for IFAs and advisers',
  subHeader:
    'Help your clients and their families plan for death and find support after loss',
  ctaText: 'Work with us',
  caption: 'Our Senior People & Talent Manager, Charlotte’s late grandparents',
  foldBgColour: 'bg-ol-secondary-50',
  image: {
    url: new URL(
      '/public/theme/brochure/images/hero_images/estate_planning_hero.avif',
      import.meta.url
    ),
    alt: 'Estate Planning Hero Image',
  },
  logos: [],
};

const ifaHubspotLink = 'https://meetings.hubspot.com/dylan151/ifas';

const featuredSources = {
  class: ['flex', 'flex-row', 'justify-center'],
  featuredSources: [
    {
      link: 'https://novawm.com/',
      image: new URL(
        '/public/theme/brochure/images/ifa/nova-logo.svg',
        import.meta.url
      ),
      alt: 'Nova Logo',
    },
    {
      link: 'https://www.kytefp.co.uk/',
      image: new URL(
        '/public/theme/brochure/images/ifa/kyte-logo.svg',
        import.meta.url
      ),
      alt: 'Kyte Logo',
    },
    {
      link: 'https://optimumpath.co.uk/',
      image: new URL(
        '/public/theme/brochure/images/ifa/optimum-path-logo.svg',
        import.meta.url
      ),
      alt: 'Optimum Logo',
    },
  ],
};

const planningHeading = 'Our estate planning services';
const planningSubheading =
  'Our legal and will writing teams use suitability reports to ensure we’re find the best estate plan for your clients.';
const planningFeatures = [
  {
    name: 'Will Writing',
    description:
      'Standard or complex wills through a channel of the client’s choice (online, phone, video call, in person).',
    icon: PencilIcon,
  },
  {
    name: 'Lasting Power of Attorney',
    description: 'End to end service, including registration with the OPG.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Trusts',
    description:
      'Trust creation, registration, administration and winding up services.',
    icon: DocumentTextIcon,
  },
  {
    name: 'Probate',
    description:
      'Both grant only and full estate administration with fixed-fee upfront pricing.',
    icon: ScaleIcon,
  },
];

const stepsSection = {
  header: 'We’re here to help you and your clients',
  ctaText: 'Get in touch',
  ctaLink: ifaHubspotLink,
  subHeader:
    'We work with financial advisers of all shapes and sizes across the UK to help your clients protect what’s important to them and obtain peace of mind. ',
  steps: [
    {
      name: 'Ensuring the best client outcomes',
      description:
        'By working collaboratively with you, we’re able to provide clients with a complete & IHT efficient estate plan; and help you meet consumer duty guidelines.',
    },
    {
      name: 'A bespoke estate planning service',
      description:
        'We offer a full range of estate planning services - covering will writing, trusts, LPAs and life insurance. We support your clients through loss with our dedicated concierge and probate services.',
    },
    {
      name: 'Easy-to-use tracking tools to help measure results',
      description:
        'Real-time data reporting allows you to track the progress of the clients that you refer, and access key information.',
    },
    {
      name: 'A partnership that works for you',
      description:
        'We tailor our approach to the way you work; from referring a client, to having your advisers join the instruction meeting, safe in the knowledge you will be kept in the loop along every step.',
    },
  ],
};

const samSection = {
  title: 'Loss made easier. By people who’ve been through it.',
  body: 'Sam founded Octopus Legacy, formerly Guardian Angel, after his Mum died in 2016. We’ve experienced first-hand how chaotic and overwhelming loss can be.\n\nOur mission is to make other people’s experience of loss easier. That is why we built the Support Hubs, they’re the tool we wished we’d had.',
};

const sectionImage = {
  image: new URL(
    '/public/theme/brochure/images/ifa/ifa-image.avif',
    import.meta.url
  ),
  alt: 'Our Software Developer Heather, and her late Uncle ',
  figureCaption: 'Our Software Developer Heather, and her late Uncle ',
  imageText1: 'Death can\ncome between us.',
  imageText2: 'Or it can bring\n us together.',
};

const showCaseFeatures = [
  {
    heading: '7 in 10',
    paragraph: 'Of clients referred will complete their estate planning',
  },
  {
    heading: 'Over 400,000',
    paragraph:
      'Customers have used Octopus Legacy to plan for death and find practical support after loss.',
  },
  {
    heading: 'Over 35 hours',
    paragraph:
      'The amount of time we save your clients by acting as professional executors on probate cases ',
  },
];
</script>

<style scoped></style>
